const ALL_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const NUMERIC_CHARACTERS = '0123456789';

function generateRandomId(length, { numeric = false } = {}) {
  const characters = numeric ? NUMERIC_CHARACTERS : ALL_CHARACTERS;
  const charactersLength = characters.length;

  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default generateRandomId;
